<template>
  <div class="comp-full-calendar">
    <!-- header pick month -->
    <fc-header :current-date="currentDate"
               :first-day="firstDay"
               :month-names="monthNames"
               :maxDate="maxDate"
               :minDate="minDate"
               @change="emitChangeMonth"
               :titleFormat="titleFormat">

      <div slot="header-left">
        <slot name="fc-header-left"> </slot>
      </div>

      <div slot="header-right">
        <slot name="fc-header-right"> </slot>
      </div>
    </fc-header>
    <!-- body display date day and events -->
    <fc-body
      :current-date="currentDate"
      :events="events"
      :month-names="monthNames"
      :week-names="weekNames"
      :first-day="firstDay"
      @eventclick="emitEventClick"
      @dayclick="emitDayClick"
      @moreclick="emitMoreClick"
    >
      <div slot="body-card">
        <slot name="fc-body-card"> </slot>
      </div>
    </fc-body>
  </div>
</template>
<script type="text/babel">
import langSets from './dataMap/langSets'
import Body from './components/body'
import Header from './components/header'
export default {
  props: {
    events: {
      // events will be displayed on calendar
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    lang: {
      type: String,
      default: 'zh'
    },
    firstDay: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      validator(val) {
        let res = parseInt(val)
        return res >= 0 && res <= 6
      },
      default: 0
    },
    titleFormat: {
      type: String,
      default() {
        return langSets[this.lang].titleFormat
      }
    },
    monthNames: {
      type: Array,
      default() {
        return langSets[this.lang].monthNames
      }
    },
    weekNames: {
      type: Array,
      default() {
        let arr = langSets[this.lang].weekNames
        return arr.slice(this.firstDay).concat(arr.slice(0, this.firstDay))
      }
    },
    currentYear: {
      type: String,
      default: '2020'
    },
    currentMonth: {
      type: String | Number,
      default: new Date().getMonth() + 1
    },
    maxDate: {
      type: String,
      default: new Date()
    },
    minDate: {
      type: String,
      default: new Date()
    }
  },
  data() {
    return {
      currentDate: this.currentYear + '/' + this.currentMonth,
      firstTime: true
    }
  },
  watch: {
    currentMonth: function() {
      this.currentDate = this.currentYear + '/' + this.currentMonth
      this.emitChangeMonth(this.currentDate, this.currentDate, this.currentDate, this.currentDate)
    },
    currentYear: function() {
      this.currentDate = this.currentYear + '/' + this.currentMonth
      this.emitChangeMonth(this.currentDate, this.currentDate, this.currentDate, this.currentDate)
    }
  },
  methods: {
    emitChangeMonth(start, end, currentStart, current) {
      if (this.firstTime) {
        this.firstTime = false
      } else {
        this.currentDate = current
      }
      this.$emit('changeMonth', current)
    },
    emitEventClick(event, jsEvent, pos) {
      this.$emit('eventClick', event, jsEvent, pos)
    },
    emitDayClick(day, jsEvent) {
      this.$emit('dayClick', day, jsEvent)
    },
    emitMoreClick(day, events, jsEvent) {
      this.$emit('moreClick', day, event, jsEvent)
    }
  },
  components: {
    FcBody: Body,
    FcHeader: Header
  }
}
</script>
