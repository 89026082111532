<template>
  <div class="activity" v-if="isMounted">
    <b-container>
      <h1 class="my-4" v-if="page">{{ page.title }}</h1>
      <b-tabs pills class="time-tabs" v-model="tabIndex">
        <b-tab :title="$t('年')" active>
          <div class="year-activity-list">
            <div v-for="(item, index) in activityList" :key="'year-activity-list' + item.month + index">
              <b-card
                border-variant="primary"
                :header="months[item.month - 1]"
                header-bg-variant="primary"
                header-text-variant="white"
                class="month-item"
                v-if="item.calendarActivityDtos.length > 0"
              >
                <div style="margin: 0 40px">
                  <div
                    class="activity-item my-3"
                    v-for="(arr, o) in item.calendarActivityDtos"
                    :key="arr[0].type + arr[0].title + o"
                    align-h="between"
                  >
                    <b-link rel="" :to="{ name: 'activity-detail', params: { id: arr[0].id } }" v-if="!isHoliday(arr)">
                      <b-row style="margin-left: 0px; margin-right: 0px; position: relative">
                        <b-col class="col-auto date">
                          {{ onlyDay(arr[0].date) }}
                        </b-col>
                        <b-col xl="10" lg="9" md="8" sm="10" cols="8">
                          <b-row class="title">
                            {{ arr[0].title }}
                          </b-row>
                          <b-row class="bottom-title">
                            <b-col class="col-auto" style="padding: 0px">{{ $t('地點') }}：</b-col>
                            <b-col v-html="arr[0].place" style="padding-left: 0px"></b-col>
                          </b-row>
                        </b-col>
                        <b-col class="col-auto" style="width: 70px; right: 0; position: absolute;height:100%;">
                          <div class="icon-btn" :title="arr[0].title"></div>
                        </b-col>
                      </b-row>
                    </b-link>
                    <b-row v-else style="margin-left: 0px; margin-right: 0px; position: relative">
                      <b-col class="col-auto date">
                        {{ onlyDay(arr[0].date) }}
                      </b-col>
                      <b-col xl="10" lg="9" md="8" sm="10" cols="8">
                        <b-row class="title">
                          {{ arr[0].title }}
                        </b-row>
                        <b-row class="bottom-title">
                          <span v-for="(a, i) in arr" :key="'bottom-title-' + a.type + i">
                            <b-badge v-if="a.type === 'macau-bank-holiday'" variant="dark" pill>{{
                              $t('澳門銀行假期')
                            }}</b-badge>
                            <b-badge v-else-if="a.type === 'macau-public-holiday'" variant="danger" pill>{{
                              $t('澳門公眾假期')
                            }}</b-badge>
                            <b-badge v-else-if="a.type === 'hongkong-public-holiday'" variant="warning" pill>{{
                              $t('香港公眾假期')
                            }}</b-badge>
                          </span>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('月')">
          <div class="content my-4" v-if="isMounted">
            <calendar
              :year="year.toString()"
              :month="month"
              :monthIndex="monthIndex"
              :maxDate="maxDate"
              :minDate="minDate"
              @changeDate="changeDate"
              :events="events"
              @eventclick="eventclick"
            ></calendar>
          </div>
          <b-badge variant="dark" style="margin-right: 10px" pill>{{ $t('澳門銀行假期') }}</b-badge>
          <b-badge variant="danger" style="margin-right: 10px" pill>{{ $t('澳門公眾假期') }}</b-badge>
          <b-badge variant="warning" pill>{{ $t('香港公眾假期') }}</b-badge>
        </b-tab>
        <!-- <b-tab title="周"> </b-tab>-->
        <template v-slot:tabs-end>
          <div class="select-box flex-row-reverse">
            <b-form-select
              class="select"
              v-model="year"
              :options="years"
              v-show="tabIndex === 0 || tabIndex === 1"
              style="border:1px solid #c7c7c7"
            ></b-form-select>
            <b-form-select
              class="select"
              v-model="month"
              :options="months"
              v-show="tabIndex === 1"
              style="border:1px solid #c7c7c7"
            ></b-form-select>
          </div>
        </template>
      </b-tabs>
    </b-container>
  </div>
</template>
<script>
import moment from 'moment'
import calendar from './components/monthCalendar'
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  components: {
    calendar
  },
  mixins: [Lifecycle],
  data() {
    return {
      isMounted: false,
      tabIndex: 0,
      year: new Date().getFullYear(),
      years: [new Date().getFullYear() + 1, new Date().getFullYear(), new Date().getFullYear() - 1],
      month: this.$t(new Date().getMonth() + 1 + '月'),
      months: [
        this.$t('1月'),
        this.$t('2月'),
        this.$t('3月'),
        this.$t('4月'),
        this.$t('5月'),
        this.$t('6月'),
        this.$t('7月'),
        this.$t('8月'),
        this.$t('9月'),
        this.$t('10月'),
        this.$t('11月'),
        this.$t('12月')
      ],
      date: '',
      activityList: [],
      events: [],
      monthIndex: new Date().getMonth() + 1 //所選月份索引
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  computed: {
    ...mapState('page', ['calendars']),
    ...mapState('common', ['page']),
    maxDate: function() {
      // let arr = this.months.map(m => m.substr(0, m.length - 1))
      // return Math.max(...this.years) + '/' + Math.max(...arr)
      return Math.max(...this.years) + '/' + this.months.length
      // return new Date(Math.max(...this.years),this.months.length)
    },
    minDate: function() {
      // let arr = this.months.map(m => m.substr(0, m.length - 1))
      // return Math.min(...this.years) + '/' + Math.min(...arr)
      return Math.min(...this.years) + '/' + 1
      // return new Date(Math.min(...this.years),0)
    }
  },
  watch: {
    month() {
      this.monthIndex = this.months.indexOf(this.$t(this.month)) + 1
    },
    year() {
      if (this.isMounted) {
        this.fetchData()
      }
    }
  },
  mounted() {
    this.fetchData()
    this.isMounted = true
  },
  methods: {
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          }),
          this.$store.dispatch('page/FETCH_CALENDAR', {
            cookies,
            year: this.year
          })
        ])
          .finally(() => {
            this.hanldeInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    hanldeInit() {
      this.calendarsContent = this.calendars
      let newArr = []
      this.calendars &&
        this.calendars.forEach(item => {
          if (item.calendarActivityDtos.length > 0) {
            newArr.push(
              Object.assign(
                {
                  calendarActivityDtos: this.groupBy(item.calendarActivityDtos, 'date')
                },
                { month: item.month }
              )
            )
          } else {
            newArr.push(Object.assign({}, item))
          }
        })
      this.activityList = newArr
      this.calendars &&
        this.calendars.forEach(cal => {
          cal.calendarActivityDtos.forEach(item => {
            let obj = null
            obj = {
              // title: item.title,
              id: item.id,
              title: item.title.replace(/-/g, '/'),
              start: item.form,
              end: item.to,
              cssClass: item.type
            }
            this.events.push(obj)
          })
        })
    },
    changeDate(y, m) {
      this.year = y
      this.month = this.months[m - 1]
    },
    isHoliday(arr) {
      let flag = false
      arr.forEach(a => {
        if (
          a.type === 'macau-bank-holiday' ||
          a.type === 'macau-public-holiday' ||
          a.type === 'hongkong-public-holiday'
        ) {
          flag = true
        }
      })
      return flag
    },
    onlyDay(date) {
      let m = date.split('-')[2].replace(/\b(0+)/gi, '')
      return m
    },
    groupBy(collection, property) {
      var i = 0,
        val,
        index,
        values = [],
        result = []
      for (; i < collection.length; i++) {
        val = collection[i][property]
        index = values.indexOf(val)
        if (index > -1) result[index].push(collection[i])
        else {
          values.push(val)
          result.push([collection[i]])
        }
      }
      return result
    },
    eventclick(event) {
      this.$router.push({
        name: 'activity-detail',
        query: {
          id: event.id
        }
      })
    }
  }
}
</script>
