<template>
  <div class="full-calendar-body">
    <div class="weeks">
      <strong class="week" v-for="(week, index) in weekNames" :key="'week' + index">{{ week }}</strong>
    </div>
    <div class="dates" ref="dates">
      <div class="dates-bg">
        <div class="week-row" v-for="(week, index) in currentDates" :key="'week-row' + index">
          <div
            class="day-cell"
            v-for="(day, o) in week"
            :class="{ today: day.isToday, 'not-cur-month': !day.isCurMonth }"
            :key="'day-cell' + o"
          >
            <b-row class="day-number" align-h="between">
              <b-col class="left col-auto">{{ day.monthDay }}</b-col>
              <b-col class="right">
                <span
                  v-for="(event, k) in day.events"
                  :key="'day-number-event-item' + k"
                  :class="[classNames(event.cssClass)]"
                  class="circle"
                  v-show="showHoliday(k, day.events)"
                ></span>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

      <!-- absolute so we can make dynamic td -->
      <div class="dates-events">
        <div class="events-week" v-for="(week, index) in currentDates" :key="'events-week' + index">
          <div
            class="events-day"
            v-for="(day, o) in week"
            :key="'events-day' + o"
            track-by="$index"
            :class="{ today: day.isToday, 'not-cur-month': !day.isCurMonth }"
            @click.stop="dayClick(day.date, $event)"
          >
            <b-row class="day-number" align-h="between">
              <b-col class="left col-auto">{{ day.monthDay }}</b-col>
              <b-col class="right">
                <span
                  v-for="(event, k) in day.events"
                  :key="'day-number-event-item' + k"
                  :class="[classNames(event.cssClass)]"
                  class="circle"
                  v-show="showHoliday(k, day.events)"
                ></span>
              </b-col>
            </b-row>
            <div class="event-box">
              <p
                class="event-item"
                v-for="(event, k) in day.events"
                :key="'event-item' + k"
                v-show="event.cellIndex <= eventLimit && event.cssClass === 'activity'"
                @click="eventClick(event, $event)"
              >
                {{ isBegin(event, day.date, day.weekDay) }}
              </p>
              <p v-if="day.events.length > 0" class="more-link" @click.stop="selectThisDay(day, $event)">
                <!-- + {{day.events[day.events.length -1].cellIndex - eventLimit}} more -->
                {{ $t('詳情') }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- full events when click show more -->
      <div class="more-events" v-show="showMore" :style="{ left: morePos.left + 'px', top: morePos.top + 'px' }">
        <div class="more-header">
          <span class="title">{{ moreTitle(selectDay.date) }}</span>
          <span class="close" @click.stop="showMore = false">x</span>
        </div>
        <div class="more-body">
          <ul class="body-list">
            <li
              v-for="(event, index) in selectDay.events"
              v-show="event.isShow"
              class="body-item"
              :class="classNames(event.cssClass)"
              @click="eventClick(event, $event)"
              :key="'body-item' + index"
            >
              {{ event.title }}
            </li>
          </ul>
        </div>
      </div>
      <slot name="body-card"></slot>
    </div>
  </div>
</template>
<script type="text/babel">
import dateFunc from './dateFunc'
export default {
  props: {
    currentDate: {},
    events: {},
    weekNames: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    monthNames: {},
    firstDay: {}
  },
  created() {
    this.events.forEach((item, index) => {
      item._id = item.id || index
      item.end = item.end || item.start
    })
    // this.events = events
  },
  data() {
    return {
      // weekNames : DAY_NAMES,
      weekMask: [1, 2, 3, 4, 5, 6, 7],
      // events : [],
      isLismit: true,
      eventLimit: 3,
      showMore: false,
      morePos: {
        top: 0,
        left: 0
      },
      selectDay: {}
    }
  },
  computed: {
    currentDates() {
      return this.getCalendar()
    }
  },
  methods: {
    isBegin(event, date, index) {
      let st = new Date(event.start)

      if (index == 0 || st.toDateString() == date.toDateString()) {
        return event.title
      }
      return '　'
    },
    moreTitle(date) {
      let dt = new Date(date)
      let index = dt.getDay() === 0 ? 6 : dt.getDay() - 1
      let dayFormat = ' ' + dt.getDate()
      let titleFormat = this.weekNames[index] + ', ' + this.monthNames[dt.getMonth()] + dayFormat
      if (this.$getLocale() != 'en') {
        dayFormat = dt.getDate() + this.$t('日')
        titleFormat = this.monthNames[dt.getMonth()] + dayFormat + ' (' + this.weekNames[index] + ')'
      }
      return titleFormat
    },
    classNames(cssClass) {
      if (!cssClass) return ''
      // string
      if (typeof cssClass == 'string') return cssClass

      // Array
      if (Array.isArray(cssClass)) return cssClass.join(' ')

      // else
      return ''
    },
    getCalendar() {
      // calculate 2d-array of each month
      // first day of this month
      let now = new Date() // today
      let date = this.currentDate

      let current = new Date(date + '/1')
      let startDate = dateFunc.getStartDate(current) // 1st day of this month

      let curWeekDay = startDate.getDay()

      // begin date of this table may be some day of last month
      let diff = parseInt(this.firstDay) - curWeekDay
      diff = diff > 0 ? diff - 7 : diff

      startDate.setDate(startDate.getDate() + diff)
      let calendar = []

      for (let perWeek = 0; perWeek < 6; perWeek++) {
        let week = []

        for (let perDay = 0; perDay < 7; perDay++) {
          week.push({
            monthDay: startDate.getDate(),
            isToday: now.toDateString() == startDate.toDateString(),
            isCurMonth: startDate.getMonth() == current.getMonth(),
            weekDay: perDay,
            date: new Date(startDate),
            events: this.slotEvents(startDate)
          })
          startDate.setDate(startDate.getDate() + 1)
          // if (startDate.toDateString() == endDate.toDateString()) {
          //   isFinal = true
          //   break
          // }
        }
        calendar.push(week)
        // if (isFinal) break
      }
      return calendar
    },
    slotEvents(date) {
      // find all events start from this date
      // let cellIndexArr = []
      let thisDayEvents = this.events.filter(day => {
        let dt = new Date(day.start)
        let st = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate())
        let ed = day.end ? new Date(day.end) : st
        // console.log('slotEvt', st, ed, date)
        return date >= st && date <= ed
      })

      // sort by duration
      thisDayEvents.sort((a, b) => {
        if (!a.cellIndex) return 1
        if (!b.cellIndex) return -1
        return a.cellIndex - b.cellIndex
      })

      // mark cellIndex and place holder
      for (let i = 0; i < thisDayEvents.length; i++) {
        thisDayEvents[i].cellIndex = thisDayEvents[i].cellIndex || i + 1
        thisDayEvents[i].isShow = true
        if (thisDayEvents[i].cellIndex == i + 1 || i > 2) continue
        thisDayEvents.splice(i, 0, {
          title: 'holder',
          cellIndex: i + 1,
          start: dateFunc.format(date, 'yyyy-MM-dd'),
          end: dateFunc.format(date, 'yyyy-MM-dd'),
          isShow: false
        })
      }

      return thisDayEvents
    },
    isStart(eventDate, date) {
      let st = new Date(eventDate)
      return st.toDateString() == date.toDateString()
    },
    isEnd(eventDate, date) {
      let ed = new Date(eventDate)
      return ed.toDateString() == date.toDateString()
    },
    selectThisDay(day, jsEvent) {
      this.selectDay = day
      this.showMore = true
      this.morePos = this.computePos(event.target)
      this.morePos.top -= 60
      let events = day.events.filter(item => {
        return item.isShow == true
      })
      this.$emit('moreclick', day.date, events, jsEvent)
    },
    computePos(target) {
      let eventRect = target.getBoundingClientRect()
      let pageRect = this.$refs.dates.getBoundingClientRect()
      let left = eventRect.left - pageRect.left
      if (left + 250 > pageRect.right) {
        left = pageRect.right - 260
      }
      return {
        left: left,
        top: eventRect.top + eventRect.height - pageRect.top
      }
    },
    dayClick(day, jsEvent) {
      this.$emit('dayclick', day, jsEvent)
    },
    eventClick(event, jsEvent) {
      if (event.cssClass === 'activity') {
        if (!event.isShow) {
          return
        }
        jsEvent.stopPropagation()
        let pos = this.computePos(jsEvent.target)
        this.$emit('eventclick', event, jsEvent, pos)
      }
    },
    showHoliday(index, events) {
      if (events[index].cssClass != 'activity') {
        let curType = events[index].cssClass
        for (let i = index + 1; i < events.length; i++) {
          if (curType === events[i].cssClass) {
            return false
          }
        }
        return true
      }
      return false
    }
  }
}
</script>
