<template>
  <div class="full-calendar-header">
    <div class="header-left">
      <!-- <slot name="header-left"></slot> -->
      <b-button class="icon prev-month"
                variant="light"
                @click.stop="goPrev"
                :disabled="isMinDate">
        <b-icon-arrow-left></b-icon-arrow-left>
      </b-button>
    </div>
    <div class="header-center">
      <span class="title">{{title}}</span>
    </div>
    <div class="header-right">
      <!-- <slot name="header-right"></slot> -->
      <b-button class="icon next-month"
                variant="light"
                @click.stop="goNext"
                :disabled="isMaxDate">
        <b-icon-arrow-right></b-icon-arrow-right>
      </b-button>
    </div>
  </div>
</template>
<script type="text/babel">
import dateFunc from './dateFunc'
import { BIconArrowLeft, BIconArrowRight } from 'bootstrap-vue'
import langSets from '../dataMap/langSets'
export default {
  components: {
    BIconArrowLeft,
    BIconArrowRight,
  },
  created() {
    this.dispatchEvent()
  },
  props: {
    currentDate: {},
    titleFormat: {},
    firstDay: {},
    monthNames: {},
    minDate: String,
    maxDate: String,
  },
  data() {
    return {
      title: this.$t(new Date().getMonth()+1+'月'),
      leftArrow: '←',
      rightArrow: '>',
      headDate: this.currentDate,
      isMinDate: false,
      isMaxDate: false,
    }
  },
  watch: {
    currentDate(val) {
      if (!val) return
      this.headDate = val
      // console.log('head-currentDate',val)
      if(typeof(new Date()) === 'object'){
        this.title = dateFunc.format(
          this.headDate,
          this.titleFormat,
          this.monthNames
        )
      }else{
        let m = this.headDate.toString().split('/')[1]
        this.title = langSets[this.$getLocale()].monthNames[m-1]
      }
      // console.log('title1',this.title)
      // this.headDate = JSON.parse(JSON.stringify(val))
    },
    headDate() {
      // let d = dateFunc.format(this.headDate, 'yyyy-M', this.monthNames)
      this.isMinDate = this.headDate === this.minDate
      this.isMaxDate = this.headDate === this.maxDate
    },
  },

  methods: {
    goPrev() {
      this.headDate = this.changeMonth(this.headDate, -1)
      this.dispatchEvent()
    },
    goNext() {
      this.headDate = this.changeMonth(this.headDate, 1)
      this.dispatchEvent()
    },
    changeMonth(date, num) {
      let arr = date.split('/')
      if(parseInt(arr[1]) === 1 && num === -1){ // 上一年
        arr[1] = langSets[this.$getLocale()].monthNames.length
        arr[0] = parseInt(arr[0]) - 1
      }else if(parseInt(arr[1]) === 12 && num === 1){ // 下一年
        arr[1] = 1
        arr[0] = parseInt(arr[0]) + 1
      }else{
        arr[1] = parseInt(arr[1]) + num
      }
      let dt = arr.join('/')
      return dt
    },
    dispatchEvent() {
      if(typeof(new Date()) === 'object'){
        this.title = dateFunc.format(
          this.headDate,
          this.titleFormat,
          this.monthNames
        )
      }else{
        let m = this.headDate.toString().split('/')[1]
        this.title = langSets[this.$getLocale()].monthNames[m-1]
      }
      let startDate = dateFunc.getStartDate(this.headDate)
      let curWeekDay = startDate.getDay()
      // 1st day of this monthView
      let diff = parseInt(this.firstDay) - curWeekDay
      if (diff) diff -= 7
      startDate.setDate(startDate.getDate() + diff)

      // the month view is 6*7
      let endDate = dateFunc.changeDay(startDate, 41)

      // 1st day of current month
      let currentDate = dateFunc.getStartDate(this.headDate)
      this.$emit(
        'change',
        // dateFunc.format(startDate, 'yyyy/'+this.titleFormat+'/dd'),
        // dateFunc.format(endDate, 'yyyy/'+this.titleFormat+'/dd'),
        // dateFunc.format(currentDate, 'yyyy/'+this.titleFormat+'/dd'),
        dateFunc.format(startDate, langSets[this.$getLocale()].dateFormat),
        dateFunc.format(endDate, langSets[this.$getLocale()].dateFormat),
        dateFunc.format(currentDate, langSets[this.$getLocale()].dateFormat),
        this.headDate
      )
    },
  },
}
</script>
