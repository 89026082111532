<template lang="zh">
<div>
  <full-calendar :events="events" :lang="lang" first-day="1"  :currentYear="year" :currentMonth="curMon" 
    :maxDate="maxDate" :minDate="minDate" @changeMonth="changeMonth" @eventClick="eventClick"></full-calendar>
</div>
</template>

<script>
import moment, { lang } from 'moment'
import fullCalendar from '@/components/fullCalendar/index'
import { lanToISO639 } from '@/utils'
export default {
  components: {
    FullCalendar: fullCalendar
  },
  props: ['year', 'month', 'maxDate', 'minDate', 'events', 'monthIndex'],
  data() {
    return {
      curYear: this.year,
      curMon: this.monthIndex,
      weekNames: ['一', '二', '三', '四', '五', '六', '日'] // 表頭名
    }
  },
  watch: {
    year: function() {
      this.curYear = this.year
    },
    month: function() {
      // this.curMon = this.month.toString().substr(0, this.month.length - 1)
      this.curMon = this.monthIndex
    }
  },
  computed: {
    lang() {
      return lanToISO639(this.$getLocale())
    }
  },
  methods: {
    changeMonth(current) {
      let dates = current.toString().split('/')
      let y = dates[0]
      // let m = dates[1].replace(/\b(0+)/gi, '')
      let m = dates[1]
      console.log(123, m, y)
      this.$emit('changeDate', y, m)
    },
    eventClick(event, jsEvent, pos) {
      this.$emit('eventclick', event)
    }
  }
}
</script>
